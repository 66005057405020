<template>
  <el-col :sm="24" :md="conf.span || 24" :key="upKey">
    <el-form-item :label="conf.label" :required="conf.required" class="upload-img">
      <el-upload
        class="img-uploader"
        :action="action"
        :headers="headers"
        accept="image/*"
        :show-file-list="false"
        :before-upload="beforeUpload"
        :on-success="handleAvatarSuccess"
        :on-error="handleAvatarError"
        :on-preview="handleCotter">
        <img v-if="conf.value" :src="conf.value" class="uploaded-img" />
        <i v-else class="el-icon-plus img-uploader-icon"></i>
      </el-upload>
      <span class="explainText">{{ conf.explain }}</span>
    </el-form-item>
  </el-col>
</template>

<script>
import { getToken } from '@/libs/auth';

export default {
  props: ["conf"],
  data() {
    return {
      upKey: "",
    };
  },
  computed: {
    action() {
      return '/api/school/common/uploadFile'
    },
    headers() {
      return {
        Authorization: getToken()
      }
    }
  },
  created() {
  },
  mounted() {},
  methods: {
    beforeUpload(file) {
      console.log("[单图片上传组件][beforeUpload]file:", file, "token:", getToken())
      let sizeCheck = true
      switch(true) {
        case ['MB','Mb','mB','mb'].includes(this.conf.sizeUnit):
          sizeCheck = file.size / 1024 / 1024 < this.conf.fileSize
          break
        case ['KB','Kb','kB','kb'].includes(this.conf.sizeUnit):
          sizeCheck = file.size / 1024 < this.conf.fileSize
          break
        case ['B','b'].includes(this.conf.sizeUnit):
          sizeCheck = file.size < this.conf.fileSize
          break
      }
      // console.log("[beforeUpload]sizeCheck:", sizeCheck)

      if (!sizeCheck) {
        this.$message.error(`上传图片大小不能超过 ${this.conf.fileSize + this.conf.sizeUnit}!`);
      }

      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      // console.log("beforeAvatarUpload file isJPG:", isJPG)

      if (!isJPG && !isPNG) {
        this.$message.error('只能上传jpg、jpeg、png格式图片!')
      }

      // console.log("beforeAvatarUpload file suffix:", file.name.substring(file.name.length - 5, file.name.length))
      const isNotJFIF = file.name.substring(file.name.length - 5, file.name.length) != ".jfif"
      if(!isNotJFIF) {
        this.$message.error('只能上传jpg、jpeg、png格式图片!')
      }

      return sizeCheck && (isJPG || isPNG) && isNotJFIF;
    },
    handleCotter(event, file, fileList){

    },
    handleAvatarSuccess(res) {
      console.log("[但图片上传组件][上传成功]res:", res)
      if (res.code == '200') {
        this.upKey = +new Date()
        this.conf.value = res.data.url
        this.$message.success('上传成功！')
      } else {
        this.$message.warning(res.msg || '上传失败，请重新上传！')
      }
    },
    handleAvatarError() {
      this.$message.warning("上传失败，请重新上传！");
    },
  },
};
</script>
