<template>
  <el-col>
    <el-form-item :label="conf.label" :required="conf.required">
      <el-upload
        ref="uploadFile"
        class="upload-demo"
        :action="action"
        :headers="headers"
        :limit="conf.limit"
        :on-exceed="handleExceed"
        :file-list="fileList"
        :data="{type: 'document'}"
        multiple
        :before-upload="beforeUpload"
        :on-success="handleSuccess"
        :on-remove="handleRemove"
        :on-error="handleError">
        <el-button size="small" type="primary">{{ conf.placeholder }}</el-button>
      </el-upload>
      <span class="explainText">{{ conf.explain }}</span>
    </el-form-item>
  </el-col>
</template>

<script>
import { getToken } from '@/libs/auth';

export default {
  props: ["conf"],
  data() {
    return {
      upKey: "",
      addbtnHide: false,
      fileList: [
        // {
        //   name: '测试回显.text'
        // }
      ]
    };
  },
  computed: {
    action() {
      return '/api/school/common/uploadFile'
    },
    headers() {
      return {
        Authorization: getToken()
      }
    }
  },
  watch: {
    // 'conf.value': {
    //   handler (val) {
    //     console.log(val)
    //   },
    //   deep: true
    // }
  },
  created() {
    if(!this.conf.value) {
      this.conf.value = []
    }else {
      console.log("[附件上传组件]this.conf.value:", this.conf.value)
      for(let item of this.conf.value) {
        this.fileList.push({
          name: item.name,
          response: {
            data: {
              url: item.url
            }
          }
        })
      }
    }
  },
  methods: {
    /*  
        *event上传后相关
        handleRemove/ 文件列表移除文件时的钩子
        handleExceed/ 文件超出个数限制时的钩子
        fileList/ 上传的文件列表array
    */ 
    handleRemove(file, fileList) {
        this.updateList(fileList)
    },
    handleExceed (data,data1,data2) {
        let num = Number(this.conf.limit);
        if (num <= data1.length) {
            this.$message.error(`最多上传${data1.length}个`)
        }
    },
    /*  
        *event上传前相关
        beforeUpload/ 上传文件之前的钩子
        handleSuccess/ 文件上传成功时的钩子
        handleError/ 文件上传失败
    */ 
    beforeUpload(file) {
      console.log(file,'filefile')
        let sizeCheck = true;
        switch(true) {
            case ['MB','Mb','mB','mb'].includes(this.conf.sizeUnit):
              sizeCheck = file.size / 1024 / 1024 < this.conf.fileSize
            break
            case ['KB','Kb','kB','kb'].includes(this.conf.sizeUnit):
              sizeCheck = file.size / 1024 < this.conf.fileSize
            break
            case ['B','b'].includes(this.conf.sizeUnit):
              sizeCheck = file.size < this.conf.fileSize
            break
        }

        if (!sizeCheck) {
            this.$message.error(`上传文件大小不能超过 ${this.conf.fileSize + this.conf.sizeUnit}!`);
        }
        // .xls/isFileXls .xlsx/isFileXlsx .doc/isFileDoc .docx/isFileDocx
        var porint;
        file.name.split('').forEach((el, idx) => {
          if (el == '.') {
            porint = idx;
          }
        });
        const fileType = file.name.split('').slice(porint+1).join('');
        console.log(fileType,'fileType')
        var fileStatus = true;
        if (fileType !== 'xls' && fileType !== 'xlsx' && fileType !== 'doc' && fileType !== 'docx' && file.type !== 'application/pdf') {
            fileStatus = false;
            this.$message.error('只能上传Excel、Word文档及PDF文件!')
        }

        return sizeCheck && fileStatus;
    },
    handleSuccess(res, file, fileList) {
        console.log("[handleSuccess]:", res, file, fileList)
        if (res.code == '200') {
            this.updateList(fileList)
            this.$message.success('上传成功！')
        } else {
            this.$message.warning(res.msg || '上传失败，请重新上传！')
            // console.log("[附件上传组件][调上传接口后]this.conf.value:", this.conf.value, "this.fileList", this.fileList)
            // console.log(this.$refs.uploadFile.uploadFiles)
            let index = this.$refs.uploadFile.uploadFiles.findIndex(e => e.uid == file.uid)
            this.$refs.uploadFile.uploadFiles.splice(index, 1)
        }
    },
    handleError() {
        this.$message.warning("上传失败，请重新上传！");
    },
    updateList(fileList) {
        this.conf.value = []
        for (let i = 0; i < fileList.length; i++) {
            if(fileList[i].response) {
                this.conf.value.push({
                    name: fileList[i].name,
                    url: fileList[i].response.data.url,
                })
            }
        }
        console.log("[附件上传组件][updateList]this.conf.value:", this.conf.value)
        if(fileList.length >= this.conf.limit) {
            this.addbtnHide = true
            console.log(fileList.length, this.conf.limit, this.addbtnHide)
        }else {
            this.addbtnHide = false
        }
    }
  },
};
</script>
