<template>
  <el-col :sm="24" :md="conf.span || 24">
    <el-form-item :label="conf.label" :required="conf.required" class="upload-img">
      <el-upload
        ref="uploadImgMulti"
        class="img-uploader"
        :class="{ 'miupload-full': addbtnHide }"
        :action="action"
        :headers="headers"
        accept="image/*"
        :show-file-list="true"
        :file-list="fileList"
        list-type="picture-card"
        :multiple="false"
        :limit="parseInt(conf.limit)"
        :before-upload="beforeUpload"
        :on-success="handleSuccess"
        :on-error="handleError"
        :on-remove="handleRemove"
        :on-exceed="handleExceed">
        <i class="el-icon-plus"></i>
      </el-upload>
      <span class="explainText">{{conf.explain}}</span>
    </el-form-item>
  </el-col>
</template>

<script>
import { getToken } from '@/libs/auth';

export default {
  props: ["conf"],
  data() {
    return {
      upKey: "",
      fileList: [],
      addbtnHide: false
    };
  },
  computed: {
    action() {
      return '/api/school/common/uploadFile'
    },
    headers() {
      return {
        Authorization: getToken()
      }
    }
  },
  created() {
    if(!this.conf.value) {
      this.conf.value = []
    }else {
      this.$nextTick(() => {
        console.log("[多图片上传组件]this.conf.value", this.conf.value, "this.$refs.uploadImgMulti:", this.$refs.uploadImgMulti)
        for(let item of this.conf.value) {
          this.fileList.push({
            url: item,
            response: {
              data: {
                url: item
              }
            }
          })
        }
      })
    }
  },
  mounted() {},
  methods: {
    beforeUpload(file) {
      // console.log("[beforeUpload]file:", file)
      let sizeCheck = true
      switch(true) {
        case ['MB','Mb','mB','mb'].includes(this.conf.sizeUnit):
          sizeCheck = file.size / 1024 / 1024 < this.conf.fileSize
          break
        case ['KB','Kb','kB','kb'].includes(this.conf.sizeUnit):
          sizeCheck = file.size / 1024 < this.conf.fileSize
          break
        case ['B','b'].includes(this.conf.sizeUnit):
          sizeCheck = file.size < this.conf.fileSize
          break
      }
      // console.log("[beforeUpload]sizeCheck:", sizeCheck)

      if (!sizeCheck) {
        this.$message.error(`上传图片大小不能超过 ${this.conf.fileSize + this.conf.sizeUnit}!`);
      }

      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      // console.log("beforeAvatarUpload file isJPG:", isJPG)

      if (!isJPG && !isPNG) {
        this.$message.error('只能上传jpg、jpeg、png格式图片!')
      }

      // console.log("beforeAvatarUpload file suffix:", file.name.substring(file.name.length - 5, file.name.length))
      const isNotJFIF = file.name.substring(file.name.length - 5, file.name.length) != ".jfif"
      if(!isNotJFIF) {
        this.$message.error('只能上传jpg、jpeg、png格式图片!')
      }

      return sizeCheck && (isJPG || isPNG) && isNotJFIF;
    },
    handleSuccess(res, file, fileList) {
      console.log("[handleSuccess]:", res, file, fileList)
      if (res.code == '200') {
        // this.conf.value.push(res.data.url)
        this.updateList(fileList)
        this.$message.success('上传成功！')
      } else {
        this.$message.warning(res.msg || '上传失败，请重新上传！')
      }
    },
    handleRemove(file, fileList) {
      // console.log("[handleRemove]:", file, fileList)
      this.updateList(fileList)
    },
    updateList(fileList) {
      this.conf.value = []
      for (let i = 0; i < fileList.length; i++) {
        // console.log("[updateList]:", fileList[i])
        if(fileList[i].response) {
          this.conf.value.push(fileList[i].response.data.url)
          // console.log("[updateList]this.conf.value:", this.conf.value)
        }
      }
      // console.log("[updateList]conf.value:", this.conf.value)
      if(fileList.length >= this.conf.limit) {
        this.addbtnHide = true
        // console.log("[updateList]:", fileList.length, this.conf.limit, this.addbtnHide)
      }else {
        this.addbtnHide = false
      }
    },
    handleError() {
      this.$message.warning("上传失败，请重新上传！");
    },
    handleExceed(){
      this.$message.warning(`最多上传${this.conf.limit}张图片`);
    },
  },
};
</script>
